'use client';

import type {FC} from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';

import {useEventListener} from '@/hooks/useEventListener';

import type {NavigationType} from '.';
import {Hamburger} from './Hamburger';
import {NavLink} from './NavLink';

const MENU_STRING = 'Meny';

type Props = {
	navigation: NavigationType;
};

export const MobileNav: FC<Props> = ({navigation}) => {
	const listRef = useRef<HTMLUListElement>(null);
	const triggerRef = useRef<HTMLButtonElement>(null);
	const navRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

	/**
	 * If user click outside the navigation,
	 * close the mobile navigation
	 */
	const handleOutsideClick = (event: MouseEvent) => {
		if (!isOpen) return;

		if (!navRef.current?.contains(event.target as Node)) {
			setIsOpen(false);
			setActiveDropdown(null);
		}
	};
	useEventListener('click', handleOutsideClick);

	/**
	 * If user press Escape key, close the mobile navigation
	 * and set focus on the trigger
	 */
	const handleEscape = (event: KeyboardEvent) => {
		if (!isOpen) return;

		if (event.key === 'Escape') {
			setIsOpen(false);
			setActiveDropdown(null);
			triggerRef.current?.focus();
		}
	};
	useEventListener('keydown', handleEscape);

	/**
	 * On first focus inside the navRef,
	 * set focusInsideNav to true
	 */
	const handleFocus = (event: FocusEvent) => {
		const insideNav = navRef.current?.contains(event.target as Node);
		if (!insideNav && isOpen) {
			setIsOpen(false);
			setActiveDropdown(null);
		}
	};
	useEventListener('focusin', handleFocus);

	/**
	 * If user opens the mobile navigation, scroll the listRef
	 * to the top
	 */
	useEffect(() => {
		if (isOpen) {
			listRef.current?.scrollTo(0, 0);
		}
	}, [isOpen]);

	const handleClick = useCallback(() => {
		if (isOpen) {
			setActiveDropdown(null);
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	}, [isOpen]);

	const handleDropdownClick = useCallback(
		(key: string) => {
			if (activeDropdown === key) {
				setActiveDropdown(null);
			} else {
				setActiveDropdown(key);
			}
		},
		[activeDropdown],
	);

	return (
		<nav className={clsx('md:hidden')} ref={navRef}>
			{/**
			 * The clickarea should be at least 48x48px for accessibility
			 */}
			<button
				ref={triggerRef}
				onClick={handleClick}
				aria-expanded={isOpen}
				className={clsx('w-12', 'h-12', 'flex', 'justify-center', 'items-center')}
			>
				<span className={clsx('sr-only')}>{MENU_STRING}</span>
				<Hamburger isOpen={isOpen} />
			</button>
			<ul
				ref={listRef}
				className={clsx(
					isOpen ? 'flex' : 'hidden',
					'flex-col',
					'absolute',
					'top-full',
					'left-0',
					'right-0',
					'bg-primary-light',
					'h-[calc(100vh-80px)]',
					'overflow-y-auto',
					'p-6',
					'z-50',
					'gap-9',
					'animate-expand',
				)}
			>
				{navigation.map((item) => {
					if (item.type === 'navlink') {
						return (
							<li key={item._key}>
								<NavLink link={item} onClick={handleClick} />
							</li>
						);
					}

					if (item.type === 'dropdown') {
						const expanded = activeDropdown === item._key;
						return (
							<li key={item._key}>
								<DropdownButton
									title={item.title ?? ''}
									expanded={expanded}
									_key={item._key}
									onClick={handleDropdownClick}
								/>
								<ul
									className={clsx(
										activeDropdown === item._key ? 'flex' : 'hidden',
										'mt-6',
										'ml-4',
										'flex-col',
										'gap-6',
									)}
								>
									{item.links?.map((link) => (
										<li key={link._key}>
											<NavLink link={link} onClick={handleClick} />
										</li>
									))}
								</ul>
							</li>
						);
					}
				})}
			</ul>
		</nav>
	);
};

type DropdownButtonProps = {
	title: string;
	expanded: boolean;
	_key: string;
	onClick: (key: string) => void;
};

const DropdownButton: FC<DropdownButtonProps> = ({title, expanded, onClick, _key}) => {
	const handleClick = useCallback(() => {
		onClick(_key);
	}, [_key, onClick]);

	return (
		<button
			onClick={handleClick}
			className={clsx(
				'inline-flex',
				'items-center',
				'gap-1',
				'no-underline',
				'font-normal',
				'hover:underline',
				'text-xl',
			)}
		>
			{title}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="1.2em"
				height="1.2em"
				viewBox="0 0 16 16"
				aria-hidden="true"
				className={clsx(expanded ? 'rotate-180' : '', 'transition-transform')}
			>
				<path
					fill="#000000"
					fillRule="evenodd"
					d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
					clipRule="evenodd"
				/>
			</svg>
		</button>
	);
};
