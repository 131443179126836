import type {FC} from 'react';
import clsx from 'clsx';

import {Button} from '../button';
import {Link} from '../link';

export type NavLinkType = {
	type: 'navlink';
	title: string;
	href: string;
	targetBlank?: boolean;
	featured?: boolean;
};

type NavLinkProps = {
	link: NavLinkType;
	onClick?: () => void;
};

export const NavLink: FC<NavLinkProps> = ({link, onClick}) => {
	if (link.featured) {
		return (
			<Button asChild size="small" className={clsx('max-md:text-xl')}>
				<Link onClick={onClick} href={link.href} targetBlank={link.targetBlank}>
					{link.title}
				</Link>
			</Button>
		);
	}

	return (
		<Link
			onClick={onClick}
			href={link.href}
			targetBlank={link.targetBlank}
			className={clsx(
				'inline-block',
				'no-underline',
				'font-normal',
				'hover:underline',
				'max-md:text-xl',
			)}
		>
			{link.title}
		</Link>
	);
};
