'use client';

import type {FC} from 'react';
import {useCallback, useRef} from 'react';
import clsx from 'clsx';

import {useEventListener} from '@/hooks/useEventListener';
import {cn} from '@/shared';
import type {KeyedArray} from '@/shared/types';

import type {NavLinkType} from './NavLink';
import {NavLink} from './NavLink';

export type DropdownType = {
	type: 'dropdown';
	title: string;
	links: KeyedArray<NavLinkType>;
};

type Props = {
	activeDropdownId: string | null;
	setActiveDropdownId: (id: string | null) => void;
	navRef: React.RefObject<HTMLDivElement>;
	id: string;
	dropdown: DropdownType;
};

export const Dropdown: FC<Props> = ({
	dropdown,
	id,
	activeDropdownId,
	setActiveDropdownId,
	navRef,
}) => {
	const triggerRef = useRef<HTMLButtonElement>(null);
	const listRef = useRef<HTMLLIElement>(null);
	const subListRef = useRef<HTMLUListElement>(null);

	const handleClick = useCallback(() => {
		setActiveDropdownId(activeDropdownId === id ? null : id);
	}, [activeDropdownId, id, setActiveDropdownId]);

	const expanded = activeDropdownId === id;

	/**
	 * If the current focus is inside subListRef and user
	 * presses the Escape key, we should collapse the dropdown
	 * and set focus on the trigger.
	 * */
	const handleEscapeOnListItems = (evt: KeyboardEvent) => {
		// Check if user has focus inside subListRef
		const focusInsideSubList = subListRef.current?.contains(document.activeElement);

		if (evt.key === 'Escape' && focusInsideSubList) {
			setActiveDropdownId(null);
			triggerRef.current?.focus();
		}
	};
	useEventListener('keydown', handleEscapeOnListItems);

	/**
	 * If the current focus is on the trigger and user
	 * presses the Escape key, we should collapse the dropdown.
	 */
	const handleEscapeOnTrigger = (evt: KeyboardEvent) => {
		if (evt.key === 'Escape' && triggerRef.current === document.activeElement) {
			setActiveDropdownId(null);
		}
	};
	useEventListener('keydown', handleEscapeOnTrigger);

	/**
	 * If the user clicks outside the navigation,
	 * we should collapse the dropdown.
	 */
	const maybeCollapse = (evt: Event) => {
		const target = evt.target as Node;

		if (activeDropdownId === null) return;

		const isOutsideElement = !navRef.current?.contains(target);

		if (isOutsideElement) {
			setActiveDropdownId(null);
		}
	};
	useEventListener('click', maybeCollapse), listRef;

	/**
	 * If the current focus is inside the dropdown and the focus
	 * leaves the dropdown, we should collapse the dropdown.
	 */
	const handleFocusLeavesDropdown = (evt: FocusEvent) => {
		const target = evt.relatedTarget as Node;

		if (activeDropdownId === null) return;

		const isOutsideElement = !listRef.current?.contains(target);

		if (isOutsideElement) {
			setActiveDropdownId(null);
		}
	};
	useEventListener('focusout', handleFocusLeavesDropdown);

	/**
	 * If the user clicks on any link inside the dropdown,
	 * we should collapse the dropdown.
	 */
	const handleLinkClick = () => {
		setActiveDropdownId(null);
	};

	return (
		<li className={clsx('relative')} ref={listRef}>
			<button
				onClick={handleClick}
				ref={triggerRef}
				aria-expanded={expanded}
				id={id}
				className={clsx(
					'inline-flex',
					'items-center',
					'gap-1',
					'no-underline',
					'font-normal',
					'hover:underline',
				)}
			>
				{dropdown.title}
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="1.2em"
					height="1.2em"
					viewBox="0 0 16 16"
					aria-hidden="true"
					className={clsx(expanded ? 'rotate-180' : '', 'transition-transform')}
				>
					<path
						fill="#000000"
						fillRule="evenodd"
						d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06"
						clipRule="evenodd"
					/>
				</svg>
			</button>
			<ul
				ref={subListRef}
				tabIndex={-1}
				aria-labelledby={id}
				className={cn([
					expanded ? 'flex' : 'hidden',
					'absolute',
					'left-1/2',
					'-translate-x-1/2',
					'top-[calc(100%+6px)]',
					'flex-col',
					'bg-primary-light',
					'p-6',
					'gap-4',
					'min-w-[200px]',
					'animate-fadeIn',
					'rounded',
				])}
			>
				{dropdown.links?.map((link) => (
					<li key={link._key}>
						<NavLink link={link} onClick={handleLinkClick} />
					</li>
				))}
			</ul>
		</li>
	);
};
